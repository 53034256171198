@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;700&family=Open+Sans:ital@1&display=swap');
/* base styles */
* {
  margin: 0;
  font-family: "Arimo";
  color: #000;
}
.navbar {
  /* padding: 20px;
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2; */

  height: 100%;  
  position: fixed; 
  z-index: 1; 
  top: 0; 
  left: 0; 
  /* background-color:#a8a8a8;  */
  transition: .5s ease; 
  overflow-x: hidden; 
  padding-top: 20px; 
  display: flex; 
  flex-direction: column; 
  border-right: #000;
}
.navbar h1 {
  color: #000;
  background-color: #8cdd99;
  border: 1px solid black;
  border-color: #000;
  padding: 3px;
}
/* .navbar .links {
  margin-left: auto;
} */
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #8cdd99;
}
/* .navbar img {
  margin-left: 16px;
} */
.content {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
}

.image-text-wrapper {
  display: flex;
  justify-content: space-between;
}

.flex-container{
  display: flex;
  flex-direction:row;
  border: 1px  solid#000;
  align-items: center;
  width: max-content;
  padding: 4px;
  background-color: #7fa0c9;
  border-radius: 5px;
}

.flex-container img{
  padding-right: 6px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}



.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.project h4 {
  margin-bottom: 1.5rem;
}

/* .Home {
  height: 80vh;
  width: 100vh;
} */

.bottom-right-content {
  position: absolute;
  bottom: 0;
  right: 0; 
  width: 200px; 
  text-align:left;
  margin-right: 100px;
  margin-bottom: 100px;
}

.typed-description {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
}

.About li, ul {
  margin-bottom: 10px;
}

.About ul {
  margin-bottom: 10px;
}